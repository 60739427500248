var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import * as S from './index.styles';
import { useLocation } from 'react-router-dom';
import useWindowDimensions from '@apps/common-utilities/src/hooks/useWindowDimensions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faClose } from '@fortawesome/free-solid-svg-icons';
export var SidebarNavLink = function (_a) {
    var children = _a.children, badge = _a.badge, props = __rest(_a, ["children", "badge"]);
    return (_jsxs(S.SidebarLink, __assign({}, props, { className: function (_a) {
            var isActive = _a.isActive;
            return isActive ? 'active' : '';
        } }, { children: [children, badge && _jsx(S.Badge, { children: badge })] })));
};
export var SidebarSubLink = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    return (_jsx(S.SidebarSubLink, __assign({}, props, { children: children })));
};
export var SidebarExternalLinkContainer = function (_a) {
    var children = _a.children;
    return (_jsx(S.SidebarExternalLinkContainer, { children: children }));
};
export var SidebarDivider = function () { return (_jsx(S.SidebarDivider, {})); };
export var SidebarSubHeading = function (_a) {
    var children = _a.children;
    return (_jsx(S.SidebarSubHeading, { children: children }));
};
export var SidebarPrivacyLinks = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    return (_jsx(S.SidebarPrivacyLinks, __assign({}, props, { children: children })));
};
var Sidebar = function (_a) {
    var children = _a.children;
    var width = useWindowDimensions().width;
    var location = useLocation();
    var _b = useState(false), open = _b[0], setOpen = _b[1];
    var _c = useState(location.pathname), lastPath = _c[0], setLastPath = _c[1];
    useEffect(function () {
        if (open && location.pathname !== lastPath) {
            setOpen(false);
        }
        setLastPath(location.pathname);
    }, [location, open]);
    return (_jsxs(S.SidebarContainer, __assign({ isSidebarOpen: open }, { children: [width < 1000 && (_jsxs(S.SidebarHamburgerContainer, __assign({ onClick: function () { return setOpen(!open); } }, { children: [_jsx(FontAwesomeIcon, { icon: (open ? faClose : faBars) }), _jsx("p", { children: "Menu" })] }))), (open || width >= 1000) && (_jsx(S.SidebarLinks, { children: children }))] })));
};
export default Sidebar;
